
  export default {
    props: {},
    data() {
      return {
        statisticItems: [
          // {
          //   label: 'Anzahl Logins',
          //   value: '1000',
          // },
          // {
          //   label: 'Anzahl Profil Aufrufe durch Jobanbieter',
          //   value: '500',
          // },
          // {
          //   label: 'Letzter Login',
          //   value: '20. Oktober, 2020 / 05:25 Uhr',
          // },
          // {
          //   label: 'Letztes Daten-Update',
          //   value: '20. Oktober, 2020 / 05:25 Uhr',
          // },
          // {
          //   label: 'Gespeicherte Suchen',
          //   value: '14',
          // },
          // {
          //   label: 'Anzahl Bewerbungen',
          //   value: '64',
          // },
          // {
          //   label: 'PromotionBasis Coins, aktueller Monat',
          //   value: '1.170',
          // },
          // {
          //   label: 'PromotionBasis Coins, gesamt',
          //   value: '24.268',
          // },
        ],
      };
    },
    async fetch() {
      const applicationsResponse = await this.$store.dispatch(
        'api/application/getMyApplications',
        {
          limit: 0,
        }
      );

      const favoritesResponse = await this.$store.dispatch(
        'api/favorite/getMyFavorites',
        {
          limit: 0,
        }
      );

      const pbCoinsResponse = await this.$store.dispatch(
        'api/candidateAccount/getPbCoins',
        {
          id: this.$auth.user.id,
        }
      );

      const numLoginsResponse = await this.$store.dispatch(
        'api/candidateAccount/getNumLogins',
        {
          id: this.$auth.user.id,
        }
      );

      const lastLoginResponse = await this.$store.dispatch(
        'api/candidateAccount/getLastLogin',
        {
          id: this.$auth.user.id,
        }
      );

      this.statisticItems.push({
        label: this.$t('dashboard.num_logins'),
        value: numLoginsResponse.data
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      });

      this.statisticItems.push({
        label: this.$t('dashboard.last_login'),
        value: lastLoginResponse.data,
      });

      this.statisticItems.push({
        label: this.$t('dashboard.created'),
        value: this.$auth.user.created,
      });

      this.statisticItems.push({
        label: this.$t('dashboard.last_data_update'),
        value: this.$auth.user.candidateProfile.lastDataUpdate,
      });

      this.statisticItems.push({
        label: this.$t('dashboard.last_image_update'),
        value: this.$auth.user.candidateProfile.lastImageUpdate,
      });

      this.statisticItems.push({
        label: this.$t('dashboard.num_applications'),
        value: (
          this.$auth.user.legacy_num_applications_archived +
          applicationsResponse.totalItems
        )
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      });

      this.statisticItems.push({
        label: this.$t('dashboard.num_favorites'),
        value: favoritesResponse.totalItems
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      });

      this.statisticItems.push({
        label: this.$t('dashboard.pbcoins_total'),
        value: pbCoinsResponse.total
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      });

      this.statisticItems.push({
        label: this.$t('dashboard.pbcoins_current_month'),
        value: pbCoinsResponse.currentMonth
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      });

      this.statisticItems.push({
        label: this.$t('dashboard.pbcoins_last_month'),
        value: pbCoinsResponse.lastMonth
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      });
    },
  };
